<template>
  <app-module-view>
    <template slot="body">
      <app-historical-dashboard-filter></app-historical-dashboard-filter>
      <div class="card">
        <div class="card-body">
          <button
            v-for="group in groups" :key="`filter_${group}`"
            class="btn btn-secondary m-r-5"
            :class="{active: groupFilter.includes(group)}"
            @click.passive="toggleGroupFilter(group, $event)"
          >
            {{ $t('historicalDashboard.' + group) }}
          </button>
        </div>
      </div>
      <div class="card" v-show="groupFilter.includes('competitors')">
        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <h3>{{ $t('historicalDashboard.competitors') }}</h3>
            </div>
          </div>
          <button
            v-for="(competitorType, idx) in competitorTypes" :key="`competitors_button_tab_${competitorType}`"
            class="btn btn-secondary m-r-5 w-80"
            :class="{active: isActiveCompetitorTab(idx)}"
            @click="selectCompetitorTab(idx)"
          >
            {{ $t('historicalDashboard.' + competitorType) }}
          </button>
          <app-line-chart
            class="m-t-10"
            :height="chartHeight"
            :chart-data="competitorsTypeData"
            :max-labels="24"
            legendPosition="bottom"
          ></app-line-chart>
          <app-preloader v-if="isLoadingGemius"></app-preloader>
          <div class="source">{{ $t('source') }}: Gemius</div>
        </div>
      </div>
      <div class="card" v-show="groups.includes('channels') && groupFilter.includes('channels')">
        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <h3>{{ $t('historicalDashboard.channels') }}</h3>
            </div>
          </div>
          <button
            v-for="(channelType, idx) in channelTypes"
            :key="`channels_button_tab_${channelType instanceof Array ? channelType.join('_') : channelType}`"
            class="btn btn-secondary m-r-5 w-80"
            :class="{active: isActiveChannelTab(idx)}"
            @click="selectChannelTab(idx)"
          >
            {{ $t('historicalDashboard.' + (channelType instanceof Array ? channelType.join('_') : channelType)) }}
          </button>
          <app-line-chart
            class="m-t-10"
            :height="chartHeight"
            :chart-data="channelsTypeData"
            :max-labels="24"
            legendPosition="bottom"
          ></app-line-chart>
          <app-preloader v-if="isLoadingGA"></app-preloader>
          <div class="source">{{ $t('source') }}: Google Analytics</div>
        </div>
      </div>
      <div class="card" v-show="groupFilter.includes('devices')">
        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <h3>{{ $t('historicalDashboard.devices') }}</h3>
            </div>
          </div>
          <button
            v-for="(deviceType, idx) in deviceTypes"
            :key="`devices_button_tab_${deviceType instanceof Array ? deviceType.join('_') : deviceType}`"
            class="btn btn-secondary m-r-5 w-80"
            :class="{active: isActiveDeviceTab(idx)}"
            @click="selectDeviceTab(idx)"
          >
            {{ $t('historicalDashboard.' + (deviceType instanceof Array ? deviceType.join('_') : deviceType)) }}
          </button>
          <app-line-chart
            class="m-t-10"
            :height="chartHeight"
            :chart-data="devicesTypeData"
            :max-labels="24"
            legendPosition="bottom"
          ></app-line-chart>
          <app-preloader v-if="isLoadingGemius"></app-preloader>
          <div class="source">{{ $t('source') }}: Gemius</div>
        </div>
      </div>
      <div class="card" v-show="groups.includes('products_perf') && groupFilter.includes('products_perf')">
        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <h3>{{ $t('historicalDashboard.products_perf') }}</h3>
            </div>
          </div>
          <button
            v-for="(productsPerfType, idx) in productsPerfTypes"
            :key="`products_perf_button_tab_${productsPerfType}`"
            class="btn btn-secondary m-r-5 w-80"
            :class="{active: isActiveProductsPerfTab(idx)}"
            @click="selectProductsPerfTab(idx)"
          >
            {{ $t(`historicalDashboard.${productsPerfType}`) }}
          </button>
          <app-line-chart
            class="m-t-10"
            :height="chartHeight"
            :chart-data="productsPerfTypeData"
            :max-labels="24"
            legendPosition="bottom"
            stacked
          ></app-line-chart>
          <app-preloader v-if="isLoadingPP"></app-preloader>
          <div class="source">{{ $t('source') }}: Eagle</div>
        </div>
      </div>
    </template>
  </app-module-view>
</template>

<script>
import moment from 'moment'
import appModuleView from '../../components/ModuleView'
import appHistoricalDashboardFilter from '../../components/filter/FilterHistoricalDashboard'
import appLineChart from '../../components/chart/LineChart'
import appPreloader from '../../components/preloader/Preloader'
import {
  COLORS,
  GROUPS,
  PLATFORM_TOTAL,
  PLATFORM_PC,
  PLATFORM_MOBILE_PHONES,
  PLATFORM_MOBILE_TABLETS,
  PRODUCT_SITE,
  UNIT_MONTH,
  P1D,
  hideChannels,
  hideProductsPerf
} from '../../model/HistoricalDashboard'

export default {
  name: 'HistoricalDashboardView',
  components: {
    appModuleView,
    appHistoricalDashboardFilter,
    appLineChart,
    appPreloader
  },
  computed: {
    chartHeight () {
      return 500
    },
    competitorTypes () {
      return ['real_users', 'page_views']
    },
    channelTypes () {
      return ['users', 'page_views', 'sessions', 'avg_session_duration', ['page_views', 'sessions']]
    },
    deviceTypes () {
      return ['real_users', 'page_views', 'visits', 'time_spent_visit', ['page_views', 'visits']]
    },
    productsPerfTypes () {
      const site = this.$store.getters['historicalDashboard/product']
      if (site !== undefined && site.id === P1D) {
        return ['article_types', 'departments']
      }
      return ['article_types']
    },
    competitorsTypeData () {
      return this._.cloneDeep(this.competitors[this.activeCompetitorTab])
    },
    channelsTypeData () {
      return this._.cloneDeep(this.channels[this.activeChannelTab])
    },
    devicesTypeData () {
      return this._.cloneDeep(this.devices[this.activeDeviceTab])
    },
    productsPerfTypeData () {
      return this._.cloneDeep(this.productsPerf[this.activeProductsPerfTab])
    },
    competitors () {
      const site = this.$store.getters['historicalDashboard/product']
      if (site === undefined) {
        return this.competitorTypes.map(() => ({
          labels: [],
          datasets: []
        }))
      }
      const siteIds = [site.id, ...site.competitors]
      const data = this.$store.getters['historicalDashboard/list'].filter(
        (item) => item.unit === this.unit && item.platform === '99' && siteIds.includes(item.site)
      )
      const labels = data.filter((item) => item.site === site.id).map((item) => moment(item.date).format(this.dateFormat))
      return this.competitorTypes.map((chartType) => {
        const datasets = siteIds.map((siteId, idx) => {
          const site = this.$store.getters['historicalDashboard/productById'](siteId)
          return this.getDataset(
            site.name,
            data.filter((item) => item.site === siteId).map((item) => this.getMetric(chartType, item.metric)),
            site.color || COLORS[idx]
          )
        })
        return {
          labels,
          datasets,
          id: chartType
        }
      })
    },
    channels () {
      const site = this.$store.getters['historicalDashboard/product']
      if (site === undefined || hideChannels(site.id)) {
        return this.channelTypes.map(() => ({
          labels: [],
          datasets: []
        }))
      }
      const channels = ['Organic Search', 'Referral', 'Direct', '(Other)', 'Social']
      const data = this.$store.getters['historicalDashboard/googleAnalyticsList'].filter(
        (item) => item.unit === this.unit && item.site === site.id && channels.includes(item.dimension.channel)
      )
      const labels = data.filter((item) => item.dimension.channel === '(Other)').map((item) => moment(item.date).format(this.dateFormat))
      return this.channelTypes.map((chartType) => {
        const datasets = channels.map((channelId, idx) => {
          return this.getDataset(
            channelId,
            data.filter((item) => item.dimension.channel === channelId).map((item) => this.getMetric(chartType, item.metric)),
            COLORS[idx]
          )
        })
        return {
          labels,
          datasets,
          id: chartType
        }
      })
    },
    devices () {
      const site = this.$store.getters['historicalDashboard/product']
      if (site === undefined) {
        return this.deviceTypes.map(() => ({
          labels: [],
          datasets: []
        }))
      }
      const platformIds = [
        PLATFORM_TOTAL,
        PLATFORM_PC,
        PLATFORM_MOBILE_PHONES,
        PLATFORM_MOBILE_TABLETS
      ]
      const data = this.$store.getters['historicalDashboard/list'].filter(
        (item) => item.unit === this.unit && site.id === item.site && platformIds.includes(item.platform)
      )
      const labels = data.filter((item) => item.platform === PLATFORM_TOTAL).map((item) => moment(item.date).format(this.dateFormat))
      return this.deviceTypes.map((chartType) => {
        const datasets = platformIds.map((platformId, idx) => {
          return this.getDataset(
            this.$t('historicalDashboard.platform.' + platformId),
            data.filter((item) => item.platform === platformId).map((item) => this.getMetric(chartType, item.metric)),
            COLORS[idx]
          )
        })
        return {
          labels,
          datasets,
          id: chartType
        }
      })
    },
    productsPerf () {
      const site = this.$store.getters['historicalDashboard/product']
      if (site === undefined) {
        return this.productsPerfTypes.map(() => ({
          labels: [],
          datasets: []
        }))
      }
      const data = this.$store.getters['historicalDashboard/productsPerfList']
        .filter((item) => item.unit === this.unit)
        .map(({ interval, departments }) => ({
          interval,
          departments: departments.filter((department) => this.isProductDepartment(site.id, department.departmentId))
        }))
      const labels = data.map((item) => moment(item.interval.startDate).format(this.dateFormat))
      return this.productsPerfTypes.map((chartType) => {
        let sets = Object.entries(this.$t('article.article_type'))
        if (chartType === 'departments') {
          sets = this.getProductDepartments(site.id)
        }
        const datasets = sets.map(([id, title], idx) => {
          let metric
          switch (chartType) {
            case 'departments':
              metric = data.map((item) => {
                const metricItem = item.departments.filter((department) => department.departmentId === id)
                if (metricItem.length > 0) {
                  return Object.values(metricItem[0].articleTypesCounter).reduce((sum, item) => sum + item, 0)
                }
                return 0
              })
              break
            case 'article_types':
            default:
              metric = data.map((item) => item.departments.reduce((sum, item) => sum + item.articleTypesCounter[id], 0))
          }
          return this.getDataset(
            title,
            metric,
            COLORS[idx],
            true
          )
        })
        return {
          labels,
          datasets,
          id: chartType
        }
      })
    },
    isLoadingGemius () {
      return this.$store.getters['historicalDashboard/isLoadingGemius']
    },
    isLoadingGA () {
      return this.$store.getters['historicalDashboard/isLoadingGA']
    },
    isLoadingPP () {
      return this.$store.getters['historicalDashboard/isLoadingPP']
    },
    groups () {
      let groups = GROUPS
      const site = this.$store.getters['historicalDashboard/product']
      if (site !== undefined) {
        if (hideChannels(site.id)) {
          groups = groups.filter((group) => group !== 'channels')
        }
        if (hideProductsPerf(site.id)) {
          groups = groups.filter((group) => group !== 'products_perf')
        }
      }
      return groups
    },
    unit () {
      return this.$store.getters['historicalDashboard/filter'].unit
    },
    dateFormat () {
      return this.unit === UNIT_MONTH ? 'MM/Y' : 'DD.MM.Y'
    }
  },
  data () {
    return {
      groupFilter: [...GROUPS],
      activeCompetitorTab: 0,
      activeChannelTab: 0,
      activeDeviceTab: 0,
      activeProductsPerfTab: 0
    }
  },
  methods: {
    toggleGroupFilter (key, event) {
      event.target.blur()
      if (this.groupFilter.includes(key)) {
        this.groupFilter = this.groupFilter.filter((k) => k !== key)
      } else {
        this.groupFilter.push(key)
      }
    },
    isActiveCompetitorTab (id) {
      return this.activeCompetitorTab === id
    },
    isActiveChannelTab (id) {
      return this.activeChannelTab === id
    },
    isActiveDeviceTab (id) {
      return this.activeDeviceTab === id
    },
    isActiveProductsPerfTab (id) {
      return this.activeProductsPerfTab === id
    },
    selectCompetitorTab (id) {
      this.activeCompetitorTab = id
    },
    selectChannelTab (id) {
      this.activeChannelTab = id
    },
    selectDeviceTab (id) {
      this.activeDeviceTab = id
    },
    selectProductsPerfTab (id) {
      this.activeProductsPerfTab = id
    },
    isProductDepartment (product, department) {
      return (this.$store.getters['department/getById'](department) || {}).site === PRODUCT_SITE[product]
    },
    getProductDepartments (product) {
      return this.$store.getters['department/all']
        .filter((department) => department.site === PRODUCT_SITE[product])
        .map(({ id, name }) => [id, name])
    },
    getMetric (label, metric) {
      return label instanceof Array ? label.map((lbl) => metric[lbl]).reduce((sum, v) => sum / v) : metric[label]
    },
    getDataset (label, data, color = '#000000', fill = false) {
      return {
        label,
        data,
        borderColor: color,
        backgroundColor: color,
        fill
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.w-80 {
  min-width: 80px;
}

.source {
  float: right;
  margin-top: -20px;
  font-size: 13px;
}
</style>
