<template>
  <section>
    <div class="card">
      <div class="card-body">
        <form @submit.prevent="search">
          <div class="row">
            <div class="col-lg-2 col-md-2">
              <app-select
                v-model="filter.product"
                :options="products"
                optionTitle="name"
                :label="$t('historicalDashboard.product')"
                id="filter_product"
                disable-form-group
                no-empty-value
              ></app-select>
            </div>
            <div class="col-lg-3 col-md-3">
              <app-datepicker
                v-model="filter.from"
                type="date"
                customDateFormat="Y-m-d"
                :label="$t('historicalDashboard.from')"
                id="filter_from"
                class="m-b-0"
              ></app-datepicker>
            </div>
            <div class="col-lg-3 col-md-3">
              <app-datepicker
                v-model="filter.to"
                type="date"
                customDateFormat="Y-m-d"
                :label="$t('historicalDashboard.to')"
                id="filter_to"
                class="m-b-0"
              ></app-datepicker>
            </div>
            <div class="col-lg-2 col-md-2">
              <app-select
                v-model="filter.unit"
                :options="units"
                :label="$t('historicalDashboard.unit.label')"
                id="filter_unit"
                disable-form-group
                no-empty-value
              ></app-select>
            </div>
            <div class="col-lg-2">
              <button class="btn btn-success pull-right m-t-30">
                {{ $t('buttons.search') }}
              </button>
              <button
                type="button"
                class="btn btn-secondary m-t-30"
                data-test="reset_filter"
                @click="reset"
              >
                {{ $t('buttons.reset_filter') }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>

<script>
import appDatepicker from '../form/Datepicker'
import appSelect from '../form/select/Select'
import { getUnits } from '../../model/HistoricalDashboard'

export default {
  name: 'HistoricalDashboardFilter',
  computed: {
    filter () {
      return this.$store.getters['historicalDashboard/filter']
    },
    products () {
      return this.$store.getters['historicalDashboard/products'].filter((product) => product.type === 'nmh')
    }
  },
  components: {
    appDatepicker,
    appSelect
  },
  methods: {
    units () {
      const { from, to } = this.filter
      const units = getUnits(from, to).map((unit) => ({
        id: unit,
        title: this.$t('historicalDashboard.unit.' + unit)
      }))
      if (units.length === 1) {
        this.filter.unit = units[0].id
      }
      return units
    },
    search () {
      this.$store.commit('historicalDashboard/setFilter', this.filter)
      this.$store.dispatch('historicalDashboard/fetch')
      this.$store.dispatch('historicalDashboard/fetchProductsPerf')
    },
    reset () {
      this.$store.dispatch('historicalDashboard/resetFilter')
      this.$store.dispatch('historicalDashboard/fetch')
      this.$store.dispatch('historicalDashboard/fetchProductsPerf')
    }
  },
  beforeCreate () {
    this.$store.dispatch('historicalDashboard/fetchProducts')
    this.$store.dispatch('historicalDashboard/fetch')
    this.$store.dispatch('historicalDashboard/fetchProductsPerf')
  }
}
</script>
